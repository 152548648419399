import { Box, ButtonGroup, useToast } from '@chakra-ui/react'
import { Formik, Field, Form } from 'formik'
import {
  CheckboxSingleControl,
  InputControl,
  SubmitButton,
  TextareaControl,
} from 'formik-chakra-ui'
import * as React from 'react'
import * as Yup from 'yup'

const initialValues = {
  name: '',
  phonenumber: '',
  email: '',
  emailOptIn: false,
  notes: '',
}

const requiredErrorMessage = 'Dit veld is verplicht'
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object({
  name: Yup.string().required(requiredErrorMessage),
  phonenumber: Yup.string()
    .matches(phoneRegExp, 'Ongeldig telefoonnummer')
    .required(requiredErrorMessage),
  email: Yup.string().email().required(requiredErrorMessage),
  emailOptIn: Yup.mixed().oneOf([true, false]),
  notes: Yup.string().required(requiredErrorMessage),
})

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = () => {
  const toast = useToast()

  const onSubmit = (data, { setSubmitting, resetForm }) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-form',
        ...data,
      }),
    })
      .then((response) => {
        setSubmitting(false)

        if (response.ok) {
          toast({
            title: 'We hebben uw aanvraag ontvangen',
            description: 'U krijgt een mail ter bevestiging',
            status: 'success',
            position: 'top',
            duration: 9000,
            isClosable: true,
          })
          resetForm()
        } else {
          toast({
            title: 'Er ging helaas iets mis',
            description: 'Geen zorgen! U kunt op een later moment terugkomen.',
            status: 'error',
            position: 'top',
            duration: 9000,
            isClosable: true,
          })
        }
      })
      .catch(() => {
        setSubmitting(false)
        toast({
          title: 'Er ging helaas iets mis',
          description: 'Geen zorgen! U kunt op een later moment terugkomen.',
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        })
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid, errors }) => (
        <Box
          borderWidth="1px"
          rounded="lg"
          p={6}
          as="form"
          onSubmit={handleSubmit}
          data-netlify={true}
          data-netlify-honeypots="bot-field"
          data-netlify-recaptcha={true}
          name="contact-form"
        >
          <h3 className="text-1xl sm:text-2xl lg:text-3xl text-center font-bold">
            Laat ons geheel vrijblijvend helpen.
          </h3>
          <Field type="hidden" name="bot-field" />
          <InputControl name="name" label="Naam" className="mb-5" />
          <InputControl
            name="phonenumber"
            label="Telefoonnummer"
            className="mb-5"
          />
          <InputControl name="email" label="E-mailadres" className="mb-5" />
          <CheckboxSingleControl name="emailOptIn" className="mb-5">
            Ik wil meer informatie per mail ontvangen, en schrijf mezelf in voor
            meer informatieve e-mails in de toekomst. Wij spammen niet, en
            sturen u alleen mails toe die daadwerkelijk van waarde zijn.
          </CheckboxSingleControl>
          <TextareaControl name="notes" label="Wens" className="mb-5" />
          <div data-netlify-recaptcha="true"></div>
          <ButtonGroup>
            <SubmitButton disabled={isSubmitting} isLoading={isSubmitting}>
              Verstuur
            </SubmitButton>
          </ButtonGroup>
        </Box>
      )}
    </Formik>
  )
}

export default ContactForm
